import React from 'react';
import styles from './BenenfitApp.module.scss';

import { useTranslation } from 'react-i18next';
import { BenefitAppProps, GOOGLE_RESOURCE_TITLE, APPLE_RESOURCE_TITLE } from './BenefitApp.types';
import googlePlayButton from '../../../static/svg/google-play.svg';
import appStoreButton from '../../../static/svg/app-store.svg';

import { CopyImageColumns } from 'src/components/CopyMediaColumns';

export const BenefitApp: React.FC<BenefitAppProps> = ({
  className = '',
  id = '',
  title,
  subtitle,
  overline,
  paragraph,
  variant,
  isLargerTitle,
  imageAlt,
  imageUrl,
  reverseOrder = false,
}) => {
  const { t } = useTranslation();
  const googleUrl = t(GOOGLE_RESOURCE_TITLE);
  const appleUrl = t(APPLE_RESOURCE_TITLE);

  const appLinks = (
    <div className={styles.benefitApp__linkContainer}>
      <a href={googleUrl} className={styles.benefitApp__link} data-cy="link-google-play">
        <img src={googlePlayButton} alt="Get it on Google Play" />
      </a>
      <a href={appleUrl} className={styles.benefitApp__link} data-cy="link-app-store">
        <img src={appStoreButton} alt="Download on the App Store" />
      </a>
    </div>
  );

  return (
    <div className={className} id={id} data-cy="benefit-app">
      <CopyImageColumns
        copyChildren={appLinks}
        title={title}
        subtitle={subtitle}
        overline={overline}
        paragraph={paragraph}
        variant={variant}
        isLargerTitle={isLargerTitle}
        reverseOrder={reverseOrder}
        imageAlt={imageAlt}
        imageUrl={imageUrl}
        isCentered
      />
    </div>
  );
};
