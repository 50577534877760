import React from 'react';

import styles from './RoadsideOtherBenefits.module.scss';

import useRoadsideAssets from 'src/components/Roadside/useRoadsideAssets';
import { IconBenefits } from 'src/components/IconBenefits';

export const RoadsideOtherBenefits: React.FC = () => {
  const assets = useRoadsideAssets();
  const { otherBenefitsHeading, otherBenefits } = assets;

  return (
    <div className={styles.roadsideOtherBenefits} data-cy="roadside-other-benefits">
      <IconBenefits
        title={otherBenefitsHeading.title}
        subtitle={otherBenefitsHeading.subtitle}
        benefits={otherBenefits}
      />
    </div>
  );
};
