import React from 'react';
import styles from './RoadsideServicesImage.module.scss';

import useRoadsideAssets from 'src/components/Roadside/useRoadsideAssets';
import FadeIn from 'src/components/FadeIn';
import { ResponsiveBackgroundImage } from 'src/components/ResponsiveBackgroundImage';

export const RoadsideServicesImage: React.FC = () => {
  const assets = useRoadsideAssets();
  const { serviceImages } = assets;

  return (
    <div data-cy="roadside-services-image">
      <FadeIn duration={0.3}>
        <ResponsiveBackgroundImage
          className={styles.roadsideServicesImage}
          bgClassName={styles.roadsideServicesImage__background}
          ariaLabel={serviceImages[0].alt}
          desktopUrl={serviceImages[0].url}
          tabletUrl={serviceImages[1].url}
          mobileUrl={serviceImages[2].url}
        />
      </FadeIn>
    </div>
  );
};
