import React from 'react';
import styles from './RoadsideBenefits.module.scss';

import useRoadsideAssets from 'src/components/Roadside/useRoadsideAssets';
import { Benefit } from 'src/components/Roadside/Roadside.types';

import { CopyImageColumns } from 'src/components/CopyMediaColumns';
import { BenefitApp } from 'src/components/BenefitApp';
import { CheckList } from 'src/components/CheckList';

export const RoadsideBenefits: React.FC = () => {
  const assets = useRoadsideAssets();
  const { benefits, help } = assets;
  const appBenefit = benefits[benefits.length - 1];

  const getSection = (benefit: Benefit, child: React.ReactNode) => {
    return (
      <section
        key={`roadside-benefits-${benefit.title}`}
        data-cy={`roadside-benefits-${benefit.title}`}
        className={styles.roadsideBenefits__item}
      >
        {child}
      </section>
    );
  };

  const getCopyChildren = (benefit: Benefit) => {
    if (benefit.list && benefit.list.length) {
      return <CheckList items={benefit.list} />;
    }
    return null;
  };

  return (
    <div className={styles.roadsideBenefits} data-cy="roadside-benefits">
      {benefits.map((benefit, index) => {
        const reverseOrder = index % 2 !== 0;

        if (index === benefits.length - 1) {
          return getSection(
            benefit,
            <BenefitApp
              id={help.downloadLink.href}
              title={appBenefit.title}
              paragraph={appBenefit.paragraph}
              imageAlt={appBenefit.image.alt}
              imageUrl={appBenefit.image.url}
              reverseOrder={reverseOrder}
            />
          );
        }

        return getSection(
          benefit,
          <CopyImageColumns
            copyChildren={getCopyChildren(benefit)}
            title={benefit.title}
            paragraph={benefit.paragraph}
            imageAlt={benefit.image.alt}
            imageUrl={benefit.image.url}
            reverseOrder={reverseOrder}
            isCentered
          />
        );
      })}
    </div>
  );
};
