// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/6U8gDHwxfZHa1gk3jlSq7g
export type Cta = {
  title: string;
  subtitle: string;
  action: string;
  overline?: string;
  images?: string[];
  link?: string;
};

type HelpLink = {
  text: string;
  href: string;
};

// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/4DPepclC0GSr1LCITa6JBx
export type Help = {
  title: string;
  subtitle: string;
  svg: string;
  downloadLink: HelpLink;
  callLink: HelpLink;
};

type Image = {
  alt: string;
  url: string;
};

// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/3ld7pEkl402i5AVqm6Mji1
export type Benefit = {
  title: string;
  paragraph: string;
  image: Image;
  list?: string[];
};

// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/5GCVvWeR10jZnTyVtU51eB
type OtherBenefitsHeading = {
  title: string;
  subtitle: string;
};

// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/38dWYQwAyHIebqNWerfoTA
export type OtherBenefit = {
  title: string;
  subtitle: string;
  svg: string;
};

// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/1DOKkch4SbZvfOZgDWz17S
export type ServiceImage = Image;

export type Assets = {
  ctas: Cta[];
  help: Help;
  benefits: Benefit[];
  otherBenefitsHeading: OtherBenefitsHeading;
  otherBenefits: OtherBenefit[];
  serviceImages: ServiceImage[];
};

export enum AssetTitle {
  Ctas = 'roadside.ctas',
  Benefits = 'roadside.benefits',
  Help = 'roadside.help',
  OtherBenefitsHeading = 'roadside.other.benefits.heading',
  OtherBenefits = 'roadside.other.benefits',
  ServiceImages = 'roadside.services.image',
}
