import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';
import { getAltText } from 'src/helpers/getAltText';
import svgDataURL from 'src/helpers/svgDataURL';
import { toContentfulCDN } from 'src/helpers/toContentfulCDN';
import { parseImages, getStrongMarkup } from 'src/helpers/parseMarkdown';

import { Assets, AssetTitle, Cta, Help, Benefit, OtherBenefit } from './Roadside.types';

export const parseCtas = (markdown: string): Cta[] => {
  const tokens = marked.lexer(markdown);
  const ctas: Cta[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        ctas.push({
          title: token.text,
          subtitle: null,
          action: null,
          overline: null,
        });
        break;
      }
      case 'list': {
        ctas[ctas.length - 1].subtitle = token.items[0].text;
        ctas[ctas.length - 1].action = token.items[1].text;
        if (token.items[2]) {
          ctas[ctas.length - 1].overline = token.items[2].text;
        }
        if (token.items[3]) {
          ctas[ctas.length - 1].link = getStrongMarkup(token.items[3].text);
        }
        break;
      }
      case 'paragraph': {
        const image = (token.tokens[0] as marked.Tokens.Image).href;
        if (!ctas[ctas.length - 1].images) {
          ctas[ctas.length - 1].images = [];
        }
        ctas[ctas.length - 1].images.push(toContentfulCDN(image));
        break;
      }
      default: {
        break;
      }
    }
  }
  return ctas;
};

export const parseHelp = (markdown: string) => {
  const tokens = marked.lexer(markdown);
  const help: Help = {
    title: null,
    subtitle: null,
    svg: null,
    downloadLink: null,
    callLink: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        help.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.text.startsWith('<svg')) {
          help.svg = svgDataURL(token.text);
        }
        help.subtitle = token.text;
        break;
      }
      case 'list': {
        help.downloadLink = {
          text: token.items[0].text,
          href: token.items[1].text,
        };
        help.callLink = {
          text: token.items[2].text,
          href: token.items[3].text,
        };
        break;
      }
      default: {
        break;
      }
    }
  }
  return help;
};

export const parseOtherBenefits = (markdown: string): OtherBenefit[] => {
  const tokens = marked.lexer(markdown);
  const otherBenefits: OtherBenefit[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        otherBenefits.push({
          title: token.text,
          subtitle: null,
          svg: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.text.startsWith('<svg')) {
          otherBenefits[otherBenefits.length - 1].svg = svgDataURL(token.text);
        }
        otherBenefits[otherBenefits.length - 1].subtitle = token.text;
        break;
      }
    }
  }

  return otherBenefits;
};

export const parseBenefits = (markdown: string): Benefit[] => {
  const tokens = marked.lexer(markdown);
  const benefits: Benefit[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        benefits.push({
          title: token.text,
          paragraph: null,
          image: null,
          list: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          benefits[benefits.length - 1].image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else if (token.tokens[0].type === 'text') {
          benefits[benefits.length - 1].paragraph = token.tokens[0].text;
        }
        break;
      }
      case 'list': {
        const items = token.items.map((item) => item.text);
        benefits[benefits.length - 1].list = items;
        break;
      }
    }
  }

  return benefits;
};

const useRoadsideAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      ctas: parseCtas(t(AssetTitle.Ctas)),
      benefits: parseBenefits(t(AssetTitle.Benefits)),
      help: parseHelp(t(AssetTitle.Help)),
      otherBenefitsHeading: parseHelp(t(AssetTitle.OtherBenefitsHeading)),
      otherBenefits: parseOtherBenefits(t(AssetTitle.OtherBenefits)),
      serviceImages: parseImages(t(AssetTitle.ServiceImages)),
    };
  }, [ready]);
};

export default useRoadsideAssets;
