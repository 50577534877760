import React from 'react';

import styles from './RoadsideCta.module.scss';

import useRoadsideAssets from 'src/components/Roadside/useRoadsideAssets';
import FadeIn from 'src/components/FadeIn';
import { Copy } from 'src/components/Copy';
import { JoinCta } from 'src/components/JoinCta';

export const RoadsideCta: React.FC = () => {
  const assets = useRoadsideAssets();
  const { ctas } = assets;
  const cta = ctas[1];

  return (
    <div className={styles.roadsideCta} data-cy="roadside-cta">
      <FadeIn duration={0.4} up>
        <div className={styles.roadsideCta__container}>
          <Copy title={cta.title} subtitle={cta.subtitle} isLargerTitle>
            <JoinCta className={`${styles.roadsideCta__link} button__primary`} testid="roadside-cta-link" />
          </Copy>
        </div>
      </FadeIn>
    </div>
  );
};
