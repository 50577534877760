import React from 'react';
import cx from 'classnames';

import styles from './IconBenefits.module.scss';
import { Benefit, Props } from './IconBenefits.types';

import FadeIn from 'src/components/FadeIn';
import { Copy } from 'src/components/Copy';

const IconBenefits: React.FC<Props> = ({ className = '', title, subtitle, benefits }) => {
  return (
    <div className={cx(styles.iconBenefits, className)} data-cy="icon-benefits">
      <FadeIn duration={0.4} up>
        <Copy title={title} paragraph={subtitle} />
      </FadeIn>
      <div className={styles.iconBenefits__grid}>
        {benefits.map((benefit) => (
          <FadeIn duration={0.3} key={`icon-benefits-${benefit.title}`}>
            <div className={styles.iconBenefits__itemContainer} data-cy={`icon-benefits-${benefit.title}`}>
              <img
                src={benefit.svg}
                alt="icon"
                className={styles.iconBenefits__itemImage}
                data-cy="icon-benefits-image"
              />
              <h3 className={styles.iconBenefits__itemTitle} data-cy="icon-benefits-title">
                {benefit.title}
              </h3>
              <p className={styles.iconBenefits__itemSubtitle} data-cy="icon-benefits-subtitle">
                {benefit.subtitle}
              </p>
            </div>
          </FadeIn>
        ))}
      </div>
    </div>
  );
};

export { IconBenefits, Benefit };
