import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Split } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';

import { useAutoLogin } from 'src/hooks/useAutoLogin';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';

import {
  RoadsideHero,
  RoadsideHelpBar,
  RoadsideBenefits,
  RoadsideOtherBenefits,
  RoadsideCta,
  RoadsideServicesImage,
} from 'src/components/Roadside';
import { SiteDisclaimer } from 'src/components/Disclaimers';

import { useTracking } from 'src/components/TrackingContext/useTracking';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import { MainFooter } from 'src/features/footer';

const Roadside: React.FC<PageProps> = () => {
  usePreventAgentAccess();
  useAutoLogin();
  const splitIsReady = isReady();
  const { isEligibleForFreeTrial } = useFreeTrial();
  const [experiments, setExperiments] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (splitIsReady) {
      const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
      setExperiments([freeTrialExperiment]);
    }
  }, [splitIsReady]);

  useTracking('roadside_view', experiments);

  return (
    <>
      <Helmet>
        <title>{t('head.title.roadside')}</title>
        <meta name="description" content={t('head.meta-description.roadside')} />
        <meta name="robots" content="all" />
      </Helmet>
      <RoadsideHero />
      <div className="container">
        <RoadsideHelpBar />
        <RoadsideBenefits />
        <RoadsideOtherBenefits />
      </div>
      <RoadsideServicesImage />
      <div className="container">
        <RoadsideCta />
      </div>
      <footer className="container">
        <SiteDisclaimer />
      </footer>

      <MainFooter />
    </>
  );
};

export default Roadside;
