import React from 'react';

import styles from './RoadsideHero.module.scss';

import FadeIn from 'src/components/FadeIn';
import { ResponsiveBackgroundImage } from 'src/components/ResponsiveBackgroundImage';
import { scrollToElementById } from 'src/helpers/scrollToElementById';
import useRoadsideAssets from 'src/components/Roadside/useRoadsideAssets';
import { JoinCta } from 'src/components/JoinCta';

export const RoadsideHero: React.FC = () => {
  const assets = useRoadsideAssets();
  const { ctas } = assets;
  const heroCta = ctas[0];

  const handleClick = (event) => {
    event.preventDefault();
    scrollToElementById('roadside-help');
  };

  return (
    <div data-cy="roadside-hero">
      <FadeIn duration={0.3}>
        <ResponsiveBackgroundImage
          className={styles.roadsideHero__backgroundImage}
          ariaLabel={heroCta.subtitle}
          desktopUrl={heroCta.images[0]}
          tabletUrl={heroCta.images[1]}
          mobileUrl={heroCta.images[2]}
        >
          <div className="container container_center">
            <FadeIn duration={0.4} up>
              <div className={styles.roadsideHero__container}>
                <div data-cy="copy">
                  <h1 className={`${styles.roadsideHero__eyebrow} color-white`} data-cy="copy-overline">
                    {heroCta.overline}
                  </h1>
                  <h2 className={`${styles.roadsideHero__title} color-white text-display_2`} data-cy="copy-title">
                    {heroCta.title}
                  </h2>
                  <h3 className="no-padding color-light-blue text-display_2" data-cy="copy-subtitle">
                    {heroCta.subtitle}
                  </h3>
                  <div>
                    <JoinCta className={`${styles.roadsideHero__link} button__primary`} testid="roadside-hero-link" />
                  </div>
                </div>
                <div className={styles.roadsideHero__helpLink}>
                  <div className="inset-s" />
                  <a
                    href="#roadside-help"
                    className="text_4"
                    dangerouslySetInnerHTML={{ __html: heroCta.link }}
                    data-cy="roadside-hero-help-link"
                    onClick={handleClick}
                  />
                </div>
              </div>
            </FadeIn>
          </div>
        </ResponsiveBackgroundImage>
      </FadeIn>
    </div>
  );
};
